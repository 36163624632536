class RssFeed {
	block;
	initialised = false;

	constructor(block) {
		this.block = block;
		this.init();
	}

	init() {
		document.addEventListener('mousemove', () => {
			if (this.initialised) return;
			this.initialised = true;
			this.initSingle();
		}, {
			once: true
		});

		document.addEventListener('touchmove', () => {
			if (this.initialised) return;
			this.initialised = true;
			this.initSingle();
		}, {
			once: true
		});
	}

	initSingle(block){
		this.feedID = this.block.getAttribute('data-feed');
		if(!this.feedID) return;
		this.feedContainer = this.block.querySelector('.block-rss-feed__feed');

		this.getFeed();
	}

	async getFeed() {
		const data = await fetch(ajaxurl + `?action=get_rss_feed&id=` + this.feedID)
			.then(response => response.json())
			.catch(error => console.log(error));

		this.parseFeed(data);
	}

	parseFeed(data) {
		// console.log(data);
		this.feedItems = data.data.item;
		this.title = data.data.title;

		this.currentFeedIndex = 0;
		this.getNextFeedItem();
		this.startFeedCycle();

		// if there is nothing in the h2, add the title
		if (!this.block.querySelector('h2').innerHTML) {
			this.block.querySelector('h2').innerHTML = this.title;
		}
	}

	startFeedCycle() {
		this.interval = setInterval(() => {
			this.getNextFeedItem();
		}, 7000);
	}

	getNextFeedItem() {
		const item = this.feedItems[this.currentFeedIndex];
		this.feedContainer.innerHTML = `
			<a href="${item.link}" class="block-rss-feed__item" target="_blank">
				${item.title}
			</a>
		`;
		this.currentFeedIndex++;
		if (this.currentFeedIndex >= this.feedItems.length) {
			this.currentFeedIndex = 0;
		}
	}


}

document.addEventListener('DOMContentLoaded', () => {
	document.querySelectorAll('.block-rss-feed').forEach((block) => {
		new RssFeed(block);
	})
});
